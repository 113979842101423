<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="团队名称:" name="corpName">
              <a-input
                v-model:value.trim="where.corpName"
                placeholder="请输入团队名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="团队类型:" name="corpType">
              <a-select
                v-model:value="where.corpType"
                placeholder="请选择团队类型"
                allow-clear
              >
                <a-select-option
                  v-for="item in corpTypeList"
                  :key="item.dictDataName"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="corpId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #crop="{ record }">
          <div>{{ record.corpName }}</div>
          <div>{{ record.corpType }}</div>
        </template>
        <template #modules="{ record }">
          <a-space>
            <a @click="openMemberList(record)">成员管理</a>
            <a @click="openMemberField(record)">团队申请</a>
          </a-space>
        </template>
        <template #pic="{ record }">
          <div class="image">
            <a-image
              :src="record.corpPic + '?width=120'"
              :width="120"
              :height="90"
            ></a-image>
          </div>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除此分组吗？"
              @confirm="remove(record)"
            >
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑弹窗 -->
    <corpInfoEdit v-model:visible="showEdit" :data="current" @done="reload" />
    <!-- 详情弹窗 -->
    <corpDetail v-model:visible="showDetail" :data="detailInfo" />
    <a-modal
      :width="1300"
      v-model:visible="showMemberList"
      :confirm-loading="loading"
      :footer="null"
    >
      <corp-member @done="reload" :corpId="corpId" />
    </a-modal>
    <!-- 团队申请 -->
    <a-modal
      :width="1300"
      v-model:visible="showCorpApprove"
      :confirm-loading="loading"
      :footer="null"
    >
      <corp-approve @done="reload" :corpId="corpId" />
    </a-modal>
  </div>
</template>

<script>
import * as corpApi from '@/api/corp/corp.js'
import corpInfoEdit from './index-edit.vue'
import corpDetail from './index-detail.vue'
import corpMember from './member/index.vue'
import corpApprove from './corpApprove/index.vue'
import * as dictApi from '@/api/dict/dictData.js'
import { createVNode } from 'vue'
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
export default {
  name: 'corpList',
  components: {
    PlusOutlined,
    DeleteOutlined,
    corpInfoEdit,
    corpDetail,
    corpMember,
    corpApprove
  },
  data() {
    return {
      showCorpApprove: false,
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '图片',
          width: 120,
          slots: {
            customRender: 'pic'
          },
          sorter: true
        },
        {
          title: '团队',
          slots: { customRender: 'crop' }
        },
        {
          title: '功能',
          slots: { customRender: 'modules' }
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      detailInfo: null,
      // 编辑表单提交状态
      editLoading: false,
      corpTypeList: [],
      showMemberList: false,
      corpId: 0
    }
  },
  mounted() {
    this.queryStoreType()
  },
  methods: {
    openMemberField(row) {
      this.showCorpApprove = true
      this.corpId = row.corpId
    },
    datasource(option, callback) {
      corpApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    /* 打开详情弹窗 */
    openDetail(row) {
      this.detailInfo = row
      this.showDetail = true
    },
    // /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    // /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      corpApi
        .deleteById(row.corpId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    // /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.corpId) }
          corpApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },

    // /* 查询商家类型 */
    queryStoreType() {
      dictApi
        .getDictData({ dictCode: 'corpType' })
        .then((res) => {
          if (res.code === 0) {
            this.corpTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },

    openMemberList(item) {
      this.showMemberList = true
      this.corpId = item.corpId
    }
  }
}
</script>

<style  scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}
</style>
